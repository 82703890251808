import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route
// } from 'react-router-dom';

import './App.css';
import { HashRouter as Router,Routes, Route } from 'react-router-dom';
import QRCode from './pages/QRCode';
// import Home from './pages/Home';
import PrivacyPolicy from './pages/privacyPolicy';
import DownloadApp from './pages/downloadApp';
import DownloadHiveApp from './pages/downloadHiveApp';

// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';

import {useDocTitle} from './component/CustomHook';
import ScrollToTop from './component/ScrollToTop';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("Park Hive");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
        <Route path="/code" Component={QRCode} />
        <Route path="/privacyPolicy" Component={PrivacyPolicy} />
        <Route path="/download-app" Component={DownloadApp} />
        <Route path="/download-hive-app" Component={DownloadHiveApp} />
        {/* <Route path="/download-app" component={DownloadApp} />
        <Route path="/download-hive-app" component={DownloadHiveApp} />  */}
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
