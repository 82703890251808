import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import QR from 'qrcode.react';
import ApiUrlsService from '../shared/api-url-service';
import axios from 'axios';
import "../Styles/Info.css";
import InformationCard from '../component/InformationCard';
import Navbar from '../component/navBar';
import NavBar from '../component/Navbar/NavBar';

const QRCode = () => {
  const location = useLocation();
  const publicKey = new URLSearchParams(location.search).get('t');

  const [privateKey, setPrivateKey] = useState("");

  useEffect(() => {
    getPrivateKey();
  });

  const getPrivateKey = async () => {
    var url = ""
    if (window.location.hostname.includes("dev") || window.location.hostname.includes("localhost")) {
      url = "https://dparkhiveapi.azurewebsites.net";
    }
    else {
      url = "https://parkhiveapi.azurewebsites.net";
    }
    await axios.get(ApiUrlsService.getPrivateKey(url, publicKey)).then(response => {
      if (response.data) {
        setPrivateKey(response.data);
      }
    }).catch(error => {
    })
  }

  return  (<div>

  {privateKey && (

      <><div className="App">
      <NavBar />
      <div className="section-container">
        <div className="home-section">
          <div className="text-section">
            
            <div className="info-section" id="services">

              <div className="info-cards-content">
                <InformationCard
                  title="Park Hive"
                  description="Show the QR Code to Checkout your vehicle"
                  element={<QR value={privateKey} />}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div></>
  )}
</div>
)};

export default QRCode;