import React, { useEffect, useState } from "react";
import Navbar from '../component/navBar';
import InformationCard from '../component/InformationCard';
import QR from 'qrcode.react';
import "../Styles/Info.css";
import NavBar from "../component/Navbar/NavBar";

const DownloadApp = () => {

    useEffect(() => {
        getHiver();
    });

    const getHiver = async () => {
        var userAgent = navigator.userAgent;

        if (/android/i.test(userAgent)) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.nubes.parkhive_hiver";
        }
        else if (/iPad|iPhone|iPod/.test(userAgent)) {
            window.location.href = "https://apps.apple.com/in/app/park-hive-hiver/id6479428699";
        }
    }

    return (
        <div className="App">
            <NavBar />
            <div className="section-container">
                <div className="home-section">
                    <div className="text-section">
                        <div className="info-section" id="services">

                            <div className="info-cards-content">
                                <InformationCard
                                    title="Android"
                                    description="Scan to download the App"
                                    element={<QR value="https://play.google.com/store/apps/details?id=com.nubes.parkhive_hiver" />}
                                />
                                <InformationCard
                                    title="IOs"
                                    description="Scan to download the App"
                                    element={<QR value="https://apps.apple.com/in/app/park-hive-hiver/id6479428699" />}
                                />

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};

export default DownloadApp;