import React from 'react';
import { Link } from 'react-router-dom';
import InformationCard from './InformationCard';
import QR from 'qrcode.react';

const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4" id='portfolio'>
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Products</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Parking Management App</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    <ul>
                                        <li>Streamlined vehicle Entry and Exit</li>
                                        <li>Control and set Pricing based on Vehicle Type</li>
                                        <li>Retrieve Collection Reports</li>
                                        <li>Manage your Employee</li>
                                        <li>Receive real time update on Collection Amount and Vehicle Check In count</li>
                                        <li>Receive an Invoice instantly</li>
                                    </ul>
                                </p>
                                <div className="info-section" id="services">
                                    <div className="info-cards-content">
                                        <InformationCard
                                            title="Park Hive - Hiver(Parking)"
                                            description="Download the App"
                                            element={<QR value="https://parkhive.co.in/#/download-app" />}
                                        />

                                    </div>
                                </div>
                                <div className="flex my-4">
                                <span className='img-store'>
                                <a href="https://play.google.com/store/apps/details?id=com.nubes.parkhive_hiver" target="_blank" rel="noreferrer">
                                <img src="../../play_store.png" height={100} width={120} alt="Park Hive" className="logo" />
                                    {/* <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Schedule Demo
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link> */}
                                    </a>
                               </span>
                               <span className='img-store'>
                               <a href="https://apps.apple.com/in/app/park-hive-hiver/id6479428699" target="_blank" rel="noreferrer">
                                <img src="../../appstore-logo.png" height={100} width={120} alt="Park Hive" className="logo" />
                                    {/* <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Schedule Demo
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link> */}
                                    </a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Parking Searching App</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    <ul>
                                        <li>Search for nearby parking for your vehicle with ease.</li>
                                        <li>Check if the parking is full.</li>
                                        <li>Check the parking facilities, such as CCTV, covered parking, nearby petrol stations, mechanic shops, and theaters.</li>
                                        <li>Know the distance.</li>
                                    </ul>
                                </p>
                                <div className="info-section" id="services">
                                    <div className="info-cards-content">
                                        <InformationCard
                                            title="Park Hive(Customer)"
                                            description="Download the App"
                                            element={<QR value="https://parkhive.co.in/#/download-hive-app" />}
                                        />

                                    </div>
                                </div>
                                <div className="flex my-4">
                                <span className='img-store'>
                                <a href="https://play.google.com/store/apps/details?id=com.nubes.parkhive" target="_blank" rel="noreferrer">
                                <img src="../../play_store.png" height={100} width={120} alt="Park Hive" className="logo" />
                                    {/* <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Schedule Demo
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link> */}
                                    </a>
                               </span>
                               <span className='img-store'>
                               <a href="https://apps.apple.com/in/app/park-hive/id6529549259" target="_blank" rel="noreferrer">
                                <img src="../../appstore-logo.png" height={100} width={120} alt="Park Hive" className="logo" />
                                    {/* <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Schedule Demo
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link> */}
                                    </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;