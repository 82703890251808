import NavBar from '../component/Navbar/NavBar';
import "../Styles/PrivacyPolicy.css";

const PrivacyPolicy = () => {

  return  (
    <><NavBar />
    <div className="policy-section">

      <h1>Privacy Poicy</h1>
      <h3>1. Information Collected:</h3>
      <p> We may collect Personal Information from you when you use this Website. Personal
        Information refers to information that identifies, relates to, describes, is capable of being
        associated with, or could reasonably be linked, directly or indirectly, with a particular individual</p>
      <h3>2. How Information is Used:</h3>
      <p> We use the collected information,</p>
      <ul>
        <li>
          To verify your identity each time you log in to the Website
        </li>
        <li>
          To manage your account and improve our Website
        </li>
        <li>
          To improving the application and keep your data for your booking history
        </li>
      </ul>

      <h3>3. Data Security:</h3>
      <p> We implement reasonable security measures to protect your personal information. However, we cannot guarantee the security of information transmitted over the internet. </p>
      <h3>4. Third-Party Disclosures:</h3>
      <p> We may share your information with third parties only in cases necessary for providing services or as required by law. </p>
      <h3>5. Cookies:</h3>
      <p> We use cookies When you access the Website, we may collect standard Internet log information including your
        IP address, browser type and language, access times and referring website addresses,You can choose to disable cookies in your browser settings.</p>
      <h3>6. Your Rights:</h3>
      <p> you have the right to access, correct, or delete your personal information. Contact us for assistance.</p>
      <h3>7. Changes to Privacy Policy:</h3>
      <p> We reserve the right to update or change our Privacy Policy. The latest version will be posted on our website. </p>

    </div></>
)};

export default PrivacyPolicy;