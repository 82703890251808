const separator = '/';

export const ApiEntity = {
    API: 'api',
    Application: 'Application',
    Member: 'Member',
    Hive:'Hive',
    Parking:'Parking'
};

export const Common = {
    Version: 'v1',
};

export default class ApiUrlsService {

    static getPrivateKey(url, publicKey) {
        return [url,ApiEntity.API,ApiEntity.Parking, Common.Version,"CheckOut", "PrivateKey", publicKey].join(separator);
    }
}